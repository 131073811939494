import { User } from "oidc-client-ts";

function getUser() {
    const oidcStorage = sessionStorage.getItem(
        `oidc.user:${process.env.REACT_APP_OIDC_AUTHORITY}:${process.env.REACT_APP_OIDC_CLIENT_ID}`
    );
    if (!oidcStorage) {
        return null;
    }
    return User.fromStorageString(oidcStorage);
}

export function secureFetch(url, options) {
    const controller = new AbortController();
    const signal = controller.signal;
    const token = getUser()?.access_token;
    const fetchOptions = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
        signal,
        ...options,
    };
    const promise = fetch(url, fetchOptions);
    return { promise, abort: () => controller.abort() };
}


export function secureBlobFetch(url, options) {
    const { promise, abort } = secureFetch(url, options);
    const blobPromise = new Promise((resolve, reject) => {
        promise
            .then(result => {
                if (result.status === 200) result.blob().then(resolve).catch(reject);
                else reject(result.status);
            })
            .catch(reject);
    });
    return { promise: blobPromise, abort };
}

export function secureJsonFetch(url, options) {
    const { promise, abort } = secureFetch(url, options);
    const jsonPromise = new Promise((resolve, reject) => {
        promise
            .then(result => {
                if (result.status === 200) result.json().then(resolve).catch(reject);
                else reject(result.status);
            })
            .catch(reject);
    });
    return { promise: jsonPromise, abort };
}

export function securePost(url, body, options) {
    const controller = new AbortController();
    const signal = controller.signal;
    const token = getUser()?.access_token;
    const fetchOptions = {
        method: "post",
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',

        },
        signal,
        ...options,
        body: JSON.stringify(body)
    };
    const promise = fetch(url, fetchOptions);
    return { promise, abort: () => controller.abort() };
}

export function securePostWithJsonResult(url, body, options) {
    const { promise, abort } = securePost(url, body, options);
    const jsonPromise = new Promise((resolve, reject) => {
        promise
            .then(result => {
                if (result.status === 200) result.json().then(resolve).catch(reject);
                else reject(result.status);
            })
            .catch(reject);
    })
    return { promise: jsonPromise, abort };
}

export default secureFetch;
